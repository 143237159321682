.footer-info {
	background-color: $lime;
	color: #fff;
	line-height: 30px;
	margin-bottom: 30px;
	position: relative;

	.fa {
		font-size: 16px;
		width: 20px;
		text-align: center;
	}

	a {
		color: #fff;
	}

	ul,
	li {
		margin: 0;
		padding: 0 20px 0 0;

		@media #{$mobile} {
			display: block;
		}
	}

	.container {
		position: relative;

		&:after {
			position: absolute;
			content: '';
			bottom: -80px;
			right: 0;
			background-image: url(../../assets/images/footer-plant.png);
			height: 151px;
			width: 337px;
		}
	}

	@media #{$mobile} {
		margin-bottom: 90px;
	}
}

.copyright {
	text-align: left;
	padding-bottom: 60px;
}