.header-image {
	background-size: cover;
	background-position: center center;
	position: relative;
	height: 330px;
	margin-bottom: 60px;
}

.alignnone{
	@media #{$tablet}{
		width:100%;
	}
}

.home-image {
	background-size: cover;
	background-position: center center;
	position: relative;
	padding: 120px 60px;
	margin-bottom: 60px;

	.home-content {
		background-color: rgba(178,187,28,0.8);
		color: #fff;
		padding: 30px;
		max-width: 600px;

		h2 {
			color: #fff;
			margin: 0 0 20px;
		}

		p {
			max-width: 70%;
		}

		@media #{$tablet}{
			max-width:100%;
		}
	}

	

	@media #{$mobile} {
		padding: 10px;
	}
}

.the-projects {
	margin: 60px 0;

	img{
		width:100%;
	}

	h2 {
		margin-bottom: 20px;
	}

	.project-thumbnail {

	}

	.project-title {
		font-size: 20px;
		line-height: 30px;
		padding-top: 30px;
		padding-bottom: 50px;
		margin: 0;
	}
}

.project-img {
	padding-top: 100%;
	background-size: cover;
	background-position: center center;
	margin-bottom: 30px;
}

.panel-group {
	margin: 60px 0;
	.panel {
		border: 0;
		box-shadow: 0 0 0 #000;
	}

	.panel-default {
		
		.panel-heading {
			border: 0px solid #000;
			background: #B2BA31;
			border-radius: 50px;
			padding: 0;

			.panel-title {
				color: #fff;

				a {
					padding: 10px 15px;
					display: block;

					&.fa {
						&:before {
							content: "\f077";
						}
					}

					&.collapsed .fa {
						&:before {
							content: "\f078";
						}
					}
				}
			}
		}

		.panel-body {
			border: 0px solid #000 !important;
		}
	}

	@media #{$mobile} {
		text-align: center;

		img {
			margin-bottom: 30px;
		}
	}
}

.chevron-list {

	h3 {
		font-weight: 400;
		font-size: 21px;
		margin-bottom: 20px;

		strong {
			font-weight: 400;
		}
	}

	p {
		max-width: 550px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			padding: 0 0 0 15px;

			p {
				margin: 0;
			}

			&:before {
			    content: "\f0da";
			    font-family: FontAwesome;
			    position: absolute;
			    left: 15px;
			}
		}
	}
}


.envira-gallery-66894 {
	.envira-lazy {
		padding-bottom: 100% !important;

		img.envira-gallery-image {
			height: calc(1140px / 2.5) !important;
			width: auto !important;
			max-width: 1000% !important;
			margin-left: 0% !important;
			position: absolute;
			top: -30%;
			left: -30%;

			@media #{$mobile} {
				height: auto !important;
				top: 0;
				left: 0;

			}
		}

		@media #{$mobile} {
			padding-bottom: 50% !important;
		}
	}
}

.envira-gallery-wrap .envira-gallery-public.envira-gallery-3-columns .envira-gallery-item {

	@media #{$tablet} {
		width: 50% !important;
	}
	@media #{$mobile} {
		width: 100% !important;
	}
}