* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	line-height: 20px;
	background-color: #fff;
	color: $body;
	font-family: $slab;
	font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 20px 0;
	line-height: 1.2em;
	color: $lime;
	font-family: $slab;
	font-weight: 900;
}

h1 {
	margin: 0 0 20px;
}

h2 {
	font-size: 24px;
}

a {
	color: $brown;

	&:hover {
		color: $brown;
	}
}

.mobile-trigger {
	display: none;

	@media #{$mobile} {
		line-height: 30px;
		padding: 10px 0;
		font-size: 24px;
		color: #fff;
		background-color: $lime;
		display: block;
		text-align: center;

		&:focus,
		&:active,
		&:hover {
			color: #fff;
		}
	}
}

.mobile {
	display: none;
	background-color: darken($lime, 2%);

	@media #{$mobile} {
		display: block;

		ul {
			list-style: none;
			padding: 0;

			a {
				padding: 15px;
				display: block;
				color: #fff;
			}
		}
	}	
}

img {
	max-width: 100%;
	height: auto;
}

.mb30 {
	margin-bottom: 30px;
}

.mb60 {
	margin-bottom: 60px;
}

.page-header {
    padding-bottom: 0px;
    margin: 0 0 20px;
    border-bottom: 1px solid #eee;
}

.hidden-xs {
	display: block;

	@media #{$mobile} {
		display: none;
	}
}