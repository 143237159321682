article.post {
	margin-bottom: 20px;
}

.entry-title {
	overflow: hidden;
	margin: 0 0 20px;
}

.entry-content {
	max-height: 100px;
	overflow: hidden;
	margin-bottom: 20px;
}

.post-thumbnails {
	padding-top: 10px;
}