// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

div.gform_wrapper {
	margin: 0;

	div.gform_body {

		input,
		textarea {
			border: 1px solid $lime;
		}

		li.gfield {
			margin: 0 0 20px;
		}

		label.gfield_label {
			font-weight: 400;
			color: $brown;
		}
	}

	div.gform_footer {
		padding: 0;
		margin: 0;
	}
}