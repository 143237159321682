.banner {
	margin: 60px 0;

	.brand {
		@media #{$tablet}{
			text-align:center;
			display:block;
			margin-bottom:22px;
		}
	}

	.btn {
		margin-top: 37px;
		float: right;

		@media #{$desktop} {
			float: none;
			margin-top: 22px;
		}

		@media #{$tablet} {
			float:none;
			display:block;
			margin:0 auto;
			width:180px;
		}
	}

	.button-container {
		@media #{$tablet}{
			margin-top:22px;
		}
	}

	@media #{$mobile} {
		text-align: center;
	}
}

.menu-container {
	text-align: right;
	.nav-primary {
		display: inline-block;
		margin-top: 42px;

		ul {
			width: 100%;

			li {
				margin-right: 0px;
				a {
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					text-transform: uppercase;
					padding: 10px 15px;
					margin: 5px 2px;

					&:hover,
					&:active,
					&:focus {
						background-color: rgba(178,187,28,0.8);
						color: #fff;
						border-radius: 50px;
					}

					@media #{$desktop} {
						padding: 10px;
						margin: 5px 1px;
					}
				}
			}

			.active {

				a {
					background-color: rgba(178,187,28,0.8);
					color: #fff;
					border-radius: 50px;
				}
			}
		}

		@media #{$desktop} {
			margin-top: 27px;
		}
	}
	@media #{$tablet} {
		text-align: center;
	}
}