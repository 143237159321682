.widget {

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			border-bottom: 1px solid $lime;

			a {
				line-height: 30px;
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

hr,
.page-header {
	border-color: $lime !important;
}