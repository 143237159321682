a.btn.btn-primary,
input[type="submit"] {
	text-transform: uppercase;
	font-size: 18px;
	border-radius: 100px;
	line-height: 20px;
	border: 0;
	padding: 20px;
	background-color: $lime;
	color: #fff;

	&:hover,
	&:active,
	&:focus {
		background-color: darken($lime, 10%);
	}
}